<template>
    <div>        
        <v-pagination @input="getItems()" color="primary" class="py-4"  v-model="$global.state.filter.pageIndex" :length="$global.state.length" />
    </div>
</template>

<script>
export default {
    created() {
        this.$global.state.filter.pageIndex = 1
    },
    methods: {
        getItems(){
            this.$eventBus.$emit(`refresh`)
        }
    },
}
</script>